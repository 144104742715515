<template>
  <v-row class="black--text pa-4">
    <v-col cols="12" sm="11" md="11" lg="11" class="mx-auto">
      <v-row align="center">
        <v-col cols="8" sm="6" md="8" lg="8">
          <span class="caption d-block blue-grey--text text--darken-3 mb-1">Cliente: </span>
          <span class="text-h5 font-weight-bold" v-text="nombre || cliente.cli_des"></span>
          <!-- <span class="text-h5 font-weight-bold" v-text="' ('+cliente.co_cli+')'"></span>        -->
        </v-col>
        <v-col cols="4" sm="6" md="4" lg="4" class="text-right">
          <span class="caption d-block blue-grey--text text--darken-3 mb-1">Nro. Pedido</span>
          <span class="text-h3 font-weight-bold secondary--text" v-text="'#'+cliente.Numero"></span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12"><v-divider /></v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="6" lg="6" class="py-0">
          <v-list>
            <v-list-item class="px-0 list-invoice-item">
              <v-list-item-content class="py-1">
                <v-list-item-title>
                  <span class="caption blue-grey--text text--darken-3">Fecha: </span>
                  <span class="font-weight-bold">{{cliente.Fecha | smartDate}}</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="px-0 list-invoice-item">
              <v-list-item-content class="py-1">
                <v-list-item-title>
                  <span class="caption blue-grey--text text--darken-3">Procesado: </span>
                  <span class="font-weight-bold">{{cliente.Procesado}}</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="px-0 list-invoice-item">
              <v-list-item-content class="py-1">
                <v-list-item-title>
                  <span class="caption blue-grey--text text--darken-3">Lista de Precio: </span>
                  <span class="font-weight-bold">Nro. {{ListaPrecio.lista}}</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
           <!--  <v-list-item class="px-0 list-invoice-item">
              <v-list-item-content class="py-1">
                <v-list-item-title>
                  <span class="caption blue-grey--text text--darken-3">Número de Guía: </span>
                  <span class="font-weight-bold">{{cliente.NGuia}}</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="px-0 list-invoice-item">
              <v-list-item-content class="py-1">
                <v-list-item-title>
                  <span class="caption blue-grey--text text--darken-3">Chofer: </span>
                  <span class="font-weight-bold">{{cliente.Chofer}}</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item> -->
          </v-list>
        </v-col>
        <v-col cols="12" class="py-0">
          <span class="caption blue-grey--text text--darken-3 mb-1">Observaciones: </span>
          <span class="font-weight-bold" v-text="cliente.Observaciones"></span>
        </v-col>
      </v-row>
      <v-row class="mt-5">
        <v-col cols="12">
          <v-data-table
            :headers="headProductos"
            :items="articulos"
            disable-pagination
            disable-sort
            hide-default-footer
            class="elevation-1"
            mobile-breakpoint="50"
          >
            <template v-slot:item.art_des="{item}" v-if="!$vuetify.breakpoint.xsOnly">
              <v-avatar size="28" class="mr-2">
                <v-img
                  contain
                  :src="`${dir_image + item.co_art}.jpg`" />
              </v-avatar>
              <span class="font-weight-bold" :class="{'text-h6': $vuetify.breakpoint.mdAndUp, 'overline': $vuetify.breakpoint.smAndDown }">{{item.art_des}}</span>
            </template>
            <template v-slot:item.TipoPrecio="{item}">
              <div class="text-center font-weight-medium primary--text">Nro. {{item.TipoPrecio | tipoPrecio}}</div>
            </template>
            <template v-slot:item.Cantidad="{item}">
              <div class="text-center font-weight-medium">{{item.Cantidad}}</div>
            </template>
            <template v-slot:item.PVP="{item}">
              <div class="text-center">
                <span class="font-weight-bold primary--text text-center">{{ListaPrecio.moneda}} {{item.PVP | FormatCurrency}}</span>
              </div>
            </template>
            <template v-slot:body.append>
              <tr class="blue-grey lighten-5">
                <td colspan="2">
                  <div class="text-right">
                    <span class="font-weight-bold text-h5">TOTAL</span>
                  </div>
                </td>
                <td>
                  <div class="text-center">
                    <span class="font-weight-bold">{{totalCajas}}</span>
                  </div>
                </td>
                <td>
                  <div :class="{'text-center': !$vuetify.breakpoint.xsOnly, 'text-right': $vuetify.breakpoint.xsOnly}">
                    <span class="font-weight-bold">{{ListaPrecio.moneda}} {{totalArticulos | FormatCurrency}}</span>
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <!-- <v-row justify="end" class="blue-grey lighten-5 mx-1">
        <v-col cols="6" sm="6" md="4" lg="4" class="text-right">
          <span class="font-weight-bold text-h5">TOTAL</span>
        </v-col>
        <v-col cols="6" sm="6" md="3" lg="3" class="text-center">
          <span class="font-weight-bold text-h5">{{totalArticulos | FormatCurrency}}</span>
        </v-col>
      </v-row> -->
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'DetailsPedido',
  props:{
    cliente:{
      type: Object,
      default: () => ({}),
    },
    articulos:{
      type: Array,
      default: () => ([]),
    },
    nombre: String,
  },
  data: () => ({
    dir_image: process.env.VUE_APP_BASE_INVENTARIO,
     headProductos: [
      { text: 'Producto', value: 'art_des', class: 'blue-grey white--text', width: '65%' },
      { text: 'Lista', value: 'TipoPrecio', class: 'blue-grey white--text text-center', align: 'center', width: '80' },
      { text: 'Cantidad', value: 'Cantidad', class: 'blue-grey white--text text-center', align:' text-center'},
      { text: 'Precio', value: 'PVP', align: 'center', class: 'blue-grey white--text text-center'},
    ],
  }),
  // methods:{
  //   async getArticulos(){
  //     try {
  //       const { data } = await this.$api.get(`vendedores/${this.cliente.Número}/pedidos/detalles`);

  //       this.articulos = data.data;
  //     }
  //     catch (error) {
  //       this.$root.$showAlert(
  //         'Lo siento, hubo un error al intentar obtener los Articulos del pedido.',
  //         'error'
  //       )
  //     }
  //   },
  // },
  computed:{
    totalArticulos(){
      return this.articulos.length > 0 ? this.articulos.reduce((acc,art) => acc + parseFloat(art.PVP.trim()), 0.0) : '0.0';
    },
    totalCajas(){
      return this.articulos.length > 0 ? this.articulos.reduce((acc,art) => acc + parseInt(art.Cantidad.trim()), 0) : '0';
    },
    ListaPrecio(){
      const [currency, numberList] = this.articulos.length > 0 ? this.articulos[0].TipoPrecio.toString().split('') : ['',''];

      return {
        moneda: currency === '9' ? '$' : 'Bs.',
        lista: numberList
      }
    }
  },
  filters:{
    tipoPrecio(val){
      const numbreLista = val.split('');
      return numbreLista[1];
    }
  }
}
</script>
<style lang="sass">
.list-invoice-item
  min-height: 28px !important
</style>
